import React, { useState } from "react";
import PhoneInput, {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Cookies from "universal-cookie";

import * as actions from "../../../store/actions/index";
import VerifyEmailPopup from "./verifyEmailDailog";

import Button from "@material-ui/core/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { is } from "date-fns/locale";

const cookies = new Cookies();

function isPhoneNumberValid(message) {
  return this.test("isPhoneNumberValid", message, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message: "Please fill in your phone number" });
    }

    if (!isPossiblePhoneNumber(value)) {
      return createError({ path, message: "Phone number is not valid" });
    }

    return true;
  });
}

Yup.addMethod(Yup.string, "isPhoneNumberValid", isPhoneNumberValid);

const informationValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(`First name can’t be blank`),
  lastName: Yup.string().required(`Last name can’t be blank`),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email can’t be blank"),
  mobileNumber: Yup.string()
    .required()
    .isPhoneNumberValid("Mobile number is not valid")
    .required(`Mobile number can't be blank`),
});

const informationValidationSchemaWechat = Yup.object().shape({
  firstName: Yup.string().required(`名字不能为空`),
  lastName: Yup.string().required(`姓氏不能为空`),
  email: Yup.string()
    .email("请输入有效的电子邮件地址")
    .required("电子邮件不能为空"),
  mobileNumber: Yup.string()
    .required()
    .isPhoneNumberValid("手机号不能为空")
    .required(`手机号不能为空`),
});

const AppleUserDetails = ({
  acPatchUserDetails,
  user,
  acPostCheckEmail,
  acPostVerifyEmail,
  verifyEmailData,
  checkEmailData,
  acGetAccessToken,
  history,
}) => {
  const isWechatLogin = localStorage.getItem("login_mode") === "wechat";
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      firstName: user.Firstname || "",
      lastName: user.Lastname || "",
      email: user.ZoomEmail || "",
      mobileNumber: `${user.MobileExtension}${user.MobileNumber}` || "",
    },
    initialTouched: {
      firstName: false,
      lastName: false,
      email: false,
      mobileNumber: false,
    },
    validationSchema: isWechatLogin
      ? informationValidationSchemaWechat
      : informationValidationSchema,
    onSubmit: (values) => handleSubmitClick(values),
  });

  const [showDailog, setShowDialog] = useState(false);

  let checkNameCreds = user && (user.Firstname || user.Lastname);
  let checkEmail = user && user.ZoomEmail;
  let checkPhone =
    user && user.MobileNumber && user.MobileNumber !== "undefined";

  const handleSubmitClick = (values) => {
    if (!checkEmail) {
      acPostCheckEmail(values.email);
      setShowDialog(true);
    } else {
      updateUserDetails(values);
    }
  };

  const updateUserDetails = (values) => {
    try {
      setLoading(true);
      acPatchUserDetails({
        reloadPage: true,
        ...values,
        mobile_number: values?.mobileNumber
          ? parsePhoneNumber(values?.mobileNumber)?.nationalNumber
          : "",
        mobile_extension: values?.mobileNumber
          ? `+${parsePhoneNumber(values?.mobileNumber)?.countryCallingCode}`
          : "",
      });
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePopupClose = () => {
    setShowDialog(false);
  };

  const handleCodeSubmit = (code) => {
    acPostVerifyEmail(code);
  };

  const handleResendCode = () => {
    acPostCheckEmail(formik.values.email);
  };

  const handleOkClick = () => {
    updateUserDetails(formik.values);
  };

  const handleAccountsMerged = () => {
    localStorage.removeItem("token");
    // history.push("/logout")
    window.location.reload();
    // acGetAccessToken(true)
  };

  const handleAskLater = () => {
    cookies.set(`phone_required_${user.Id}`, true, {
      path: "/",
      maxAge: 86400,
    });
    window.location.reload();
  };

  return (
    // <Layout>
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full h-screen flex bg-white dark:bg-slate-800">
          <div
            className="mx-auto my-auto max-w-lg border border-slate-300 rounded-3xl p-8 space-y-6"
            style={{ boxShadow: "0px 0px 56px rgba(0, 0, 0, 0.15)" }}
          >
            <div className="text-4xl font-bold">
              {isWechatLogin ? `完成您的个人资料` : `Welcome to Spiking!`}
            </div>
            <div className="text-base inline-block font-semibold">
              {isWechatLogin
                ? `完成您的个人资料，随时获取 Spiking 最新动态！`
                : `Complete your profile to receive the latest Spiking updates!`}
            </div>
            <div className="space-y-5">
              {(!user.Firstname || !user.Lastname) && (
                <div className="grid grid-cols-2 gap-4">
                  <div className="relative">
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      onBlur={formik.handleBlur}
                      disabled={user.Firstname}
                      className=" text-[14px] border-[1.5px] border-[#D0D0D0] h-10 rounded-md block p-1 px-5 w-full text-gray-900 autofill:!shadow-[0_0_0px_1000px_#ffffff_inset] disabled:text-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary appearance-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                    />
                    <label
                      htmlFor="firstName"
                      className="absolute text-gray-500 duration-200 pointer-events-none transform -translate-y-5 scale-[.85] rounded-lg top-px z-10 origin-[0] peer- bg-white px-1 py-[2px] peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-px peer-focus:scale-[.85] peer-focus:-translate-y-5 left-2"
                    >
                      {isWechatLogin ? `名字` : `First name`}
                    </label>
                    {formik.errors.firstName && (
                      <span className="mt-2 text-red-600 text-sm absolute right-0 -bottom-5">
                        {formik.errors.firstName}
                      </span>
                    )}
                  </div>
                  <div className="relative">
                    <input
                      type="text"
                      id="lastName"
                      disabled={user.Lastname}
                      onBlur={formik.handleBlur}
                      name="lastName"
                      className=" text-[14px] border-[1.5px] border-[#D0D0D0] h-10 rounded-md block p-1 px-5 w-full text-gray-900 autofill:!shadow-[0_0_0px_1000px_#ffffff_inset] disabled:text-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary appearance-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                    />
                    <label
                      htmlFor="lastName"
                      className="absolute text-gray-500 duration-200 pointer-events-none transform -translate-y-5 scale-[.85] rounded-lg top-px z-10 origin-[0] peer- bg-white px-1 py-[2px] peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-px peer-focus:scale-[.85] peer-focus:-translate-y-5 left-2"
                    >
                      {isWechatLogin ? `姓氏` : `Last name`}
                    </label>
                    {formik.errors.lastName && (
                      <span className="mt-2 text-red-600 text-sm absolute right-0 -bottom-5">
                        {formik.errors.lastName}
                      </span>
                    )}
                  </div>
                </div>
              )}
              {!user.ZoomEmail && (
                <div className="relative">
                  <input
                    type="text"
                    id="email"
                    disabled={user.ZoomEmail}
                    onBlur={formik.handleBlur}
                    name="email"
                    className=" text-[14px] border-[1.5px] border-[#D0D0D0] h-10 rounded-md block p-1 px-5 w-full text-gray-900 autofill:!shadow-[0_0_0px_1000px_#ffffff_inset] disabled:text-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary appearance-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  <label
                    htmlFor="email"
                    className="absolute text-gray-500 duration-200 pointer-events-none transform -translate-y-5 scale-[.85] rounded-lg top-px z-10 origin-[0] peer- bg-white px-1 py-[2px] peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-px peer-focus:scale-[.85] peer-focus:-translate-y-5 left-2"
                  >
                    {isWechatLogin ? `邮箱` : `Email`}
                  </label>
                  {formik.errors.email && (
                    <span className="mt-2 text-red-600 text-sm absolute right-0 -bottom-5">
                      {formik.errors.email}
                    </span>
                  )}
                </div>
              )}
              {!checkPhone && (
                <div className="relative">
                  <PhoneInput
                    international
                    countryCallingCodeEditable={true}
                    id="mobileNumber"
                    name="mobileNumber"
                    onBlur={formik.handleBlur}
                    defaultCountry={"SG"}
                    value={formik.values.mobileNumber}
                    onChange={(value) =>
                      formik.setFieldValue("mobileNumber", value)
                    }
                    className="relative flex items-center border-[1.5px] border-[#D0D0D0] h-10 rounded-md p-1 px-5 w-full text-gray-900 autofill:!shadow-[0_0_0px_1000px_#ffffff_inset] disabled:text-gray-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary appearance-none focus-within:border-blue-600 peer"
                  />
                  {formik.errors.mobileNumber && (
                    <span className="mt-2 text-red-600 text-sm w-full left-0 -bottom-4 py-1 px-1.5">
                      {formik.errors.mobileNumber}
                    </span>
                  )}
                </div>
              )}
            </div>

            <div className="flex justify-evenly mt-5 gap-x-3">
              <Button
                type="submit"
                disabled={
                  loading ||
                  formik.errors.email ||
                  formik.errors.mobileNumber ||
                  formik.errors.firstName ||
                  formik.errors.lastName
                }
                variant="contained"
                color="primary"
                className="w-full !shadow-none !rounded-md"
              >
                Update now!
              </Button>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                className="w-full !shadow-none !rounded-md"
                onClick={() => history.push("/logout")}
              >
                Logout
              </Button>
            </div>
          </div>
        </div>
      </form>
      {checkEmailData && (
        <VerifyEmailPopup
          open={showDailog}
          handleClose={handlePopupClose}
          email={formik.values.email}
          handleCodeSubmit={handleCodeSubmit}
          handleResendCode={handleResendCode}
          verifyEmailData={verifyEmailData}
          handleOkClick={handleOkClick}
          handleSuccessClose={handleOkClick}
          checkEmailData={checkEmailData}
          handleAccountsMerged={handleAccountsMerged}
        />
      )}
    </>
    // </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.home.user,
    verifyEmailData: state.login.verifyEmailData,
    checkEmailData: state.login.checkEmailData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    acPatchUserDetails: (obj) => dispatch(actions.patchUserDetails(obj)),
    acPostCheckEmail: (email) => dispatch(actions.postCheckEmail(email)),
    acPostVerifyEmail: (code) => dispatch(actions.postVerifyEmail(code)),
    acGetAccessToken: (fromAxios) =>
      dispatch(actions.getAccessToken(fromAxios)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppleUserDetails));
