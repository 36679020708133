import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import SportsScoreOutlinedIcon from "@mui/icons-material/SportsScoreOutlined";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import MilitaryTechOutlinedIcon from "@mui/icons-material/MilitaryTechOutlined";

export const menuItems = [
  {
    label: "Welcome to World Class Investing",
    icon: <RocketLaunchOutlinedIcon />,
    scale: 0.7,
    children: [
      {
        label: "Your Future Starts Here",
        to: "/your-future",
        subLabel: "Quick Guide",
        highlight: true,
      },
    ],
  },
  {
    label: "COSY Start",
    icon: <SportsScoreOutlinedIcon />,
    scale: 0.7,
    children: [
      {
        label: "Cryptos",
        children: [
          {
            label: "Market Cap",
            subLabel: "All-Time",
            to: "/cryptos?tags=market_cap",
          },
          { label: "Pump & Dump", subLabel: "6-Hour", to: "/cryptos?tags=6h" },
          { label: "FOMO", subLabel: "12-Hour", to: "/cryptos?tags=12h" },
          { label: "DYOR", subLabel: "24-Hour", to: "/cryptos?tags=24h" },
          { label: "MOON", subLabel: "48-Hour", to: "/cryptos?tags=48h" },
          { label: "Video Tutorial", to: "/videoplay?type=cryptos" },
        ],
      },
      {
        label: "Options",
        subLabel: "Coming soon",
        to: "#",
        children: [
          {
            label: "Open Interest",
            subLabel: "9.30am-10.00am",
            to: "/options/open-interest",
          },
          {
            label: "IV Change",
            subLabel: "10.00am-10.30am",
            to: "/options/iv-change",
          },
          {
            label: "Block Quantity",
            subLabel: "10.30am-1.00pm",
            to: "/options/block-quantity",
          },
          {
            label: "Block Quality",
            subLabel: "1.00pm-3.30pm",
            to: "/options/block-quality",
          },
          {
            label: "Qty % Avg Volume",
            subLabel: "3.30pm-4.00pm",
            to: "/options/avg-volume",
          },
          { label: "Video Tutorial", to: "/videoplay?type=options" },
        ],
      },
      {
        label: "Stocks",
        children: [
          { label: "Daily Bread", subLabel: "1-Day", to: "/stocks/dailybread" },
          {
            label: "Resurrection",
            subLabel: "1-Month",
            to: "/stocks/resurrection?tags=net_positive_purchase",
          },
          {
            label: "Triangulation",
            subLabel: "1-Quarter",
            to: "/stocks/triangulation?tags=net_positive_purchase",
          },
          { label: "GOAT", subLabel: "1-Year", to: "/stocks/goat" },
          { label: "Buyback", subLabel: "1-Election", to: "/stocks/buyback" },
          { label: "Trillion", subLabel: "1-Decade", to: "/stocks/trillion" },
          { label: "YOLO", subLabel: "1-Lifetime", to: "/stocks/yolo" },
          { label: "GRIT", subLabel: "Generations", to: "/stocks/grit" },
          { label: "Video Tutorial", to: "/videoplay?type=stocks" },
        ],
      },
      {
        label: "Yes!",
        to: "/youtube/lNe_LpGSZ3g",
      },
    ],
  },
  {
    label: "SALT Journey",
    icon: <ExploreOutlinedIcon />,
    scale: 0.7,
    tooltip:
      "SALT: Share insights, leverage AI, keep Learning, and Trade with proprietary data.",
    children: [
      {
        label: "Share",
        children: [
          { label: "Join Us", to: "https://www.spiking.com/join" },
          {
            label: "Global Community",
            to: "https://www.facebook.com/groups/spiking",
          },
          { label: "World Championship", to: "https://worldchampionship.com" },
        ],
      },
      {
        label: "AI",
        children: [{ label: "TradeGPT", to: "/prompt" }],
      },
      {
        label: "Learn",
        children: [
          { label: "Courses", to: "/courses" },
          { label: "Calendar", to: "/calendar" },
        ],
      },
      {
        label: "Trade",
        children: [
          { label: "Discover", to: "/discover" },
          { label: "Portfolio", to: "/copy-trade-portfolio" },
          { label: "Paper Trade", to: "/paper-trade/trade-portfolio" },
        ],
      },
    ],
  },
  {
    label: "MONEY Finish",
    icon: <EmojiEventsOutlinedIcon />,
    scale: 0.7,
    children: [
      {
        label: "Millionaire",
        to: "/youtube/RYn7vd2b-q8",
      },
      {
        label: "Opportunity",
        to: "/youtube/rtNLts64wRk",
      },
      {
        label: "Network",
        to: "/youtube/vmfjy5OHt10",
      },
      {
        label: "Empower",
        to: "/youtube/kpqr4vej_5E",
      },
      {
        label: "You",
        to: "/youtube/cAFyeHyXyno",
      },
    ],
  },
  {
    label: "REST Together",
    icon: <AddShoppingCartOutlinedIcon />,
    scale: 0.7,
    children: [
      { label: "Refer", to: "/refer" },
      { label: "Earn", to: "/wallet" },
      { label: "Shop", to: "/shop" },
      { label: "Track", to: "/track" },
    ],
  },
  {
    isDivider: true,
  },
  {
    label: "Spiking Up",
    icon: <AutoGraphOutlinedIcon />,
    scale: 0.7,
    children: [
      {
        label: "Daily Movers",
        to: "/spiking-up-down?category=daily-movers&sortby=&sortorder=&sector=&industry=&spike=&spiking=&start=&end=",
      },
      {
        label: "Weekly Movers",
        to: "/spiking-up-down?category=weekly-movers&sortby=&sortorder=&sector=&industry=&spike=&spiking=&start=&end=",
      },
      {
        label: "Analyst",
        to: "/spiking-up-down?sector=&industry=&category=all&spike=analyst&spiking=&start=&end=&sortby=&sortorder=&drawer=true",
      },
      {
        label: "Earnings",
        to: "/spiking-up-down?sector=&industry=&category=all&spike=earnings&spiking=&start=&end=&sortby=&sortorder=&drawer=true",
      },
      {
        label: "Media",
        to: "/spiking-up-down?sector=&industry=&category=all&spike=media&spiking=&start=&end=&sortby=&sortorder=&drawer=true",
      },
      {
        label: "Strategy",
        to: "/spiking-up-down?sector=&industry=&category=all&spike=strategy&spiking=&start=&end=&sortby=&sortorder=&drawer=true",
      },
      {
        label: "Dividend",
        to: "/spiking-up-down?sector=&industry=&category=all&spike=dividend&spiking=&start=&end=&sortby=&sortorder=&drawer=true",
      },
      {
        label: "Buyback",
        to: "/spiking-up-down?sector=&industry=&category=all&spike=buyback&spiking=&start=&end=&sortby=&sortorder=&drawer=true",
      },
      {
        label: "Bond",
        to: "/spiking-up-down?sector=&industry=&category=all&spike=bond&spiking=&start=&end=&sortby=&sortorder=&drawer=true",
      },
      {
        label: "Public Offering",
        to: "/spiking-up-down?sector=&industry=&category=all&spike=public_offering&spiking=&start=&end=&sortby=&sortorder=&drawer=true",
      },
      {
        label: "IPO",
        to: "/spiking-up-down?sector=&industry=&category=all&spike=ipo&spiking=&start=&end=&sortby=&sortorder=&drawer=true",
      },
      {
        label: "Stock Split",
        to: "/spiking-up-down?sector=&industry=&category=all&spike=stock_split&spiking=&start=&end=&sortby=&sortorder=&drawer=true",
      },
      {
        label: "Resignation",
        to: "/spiking-up-down?sector=&industry=&category=all&spike=resignation&spiking=&start=&end=&sortby=&sortorder=&drawer=true",
      },
      {
        label: "FDA Approval",
        to: "/spiking-up-down?sector=&industry=&category=all&spike=fda_approval&spiking=&start=&end=&sortby=&sortorder=&drawer=true",
      },
      {
        label: "Index Addition",
        to: "/spiking-up-down?sector=&industry=&category=all&spike=index_addition&spiking=&start=&end=&sortby=&sortorder=&drawer=true",
      },
      {
        label: "Delisting",
        to: "/spiking-up-down?sector=&industry=&category=all&spike=delisting&spiking=&start=&end=&sortby=&sortorder=&drawer=true",
      },
      {
        label: "Notes Offering",
        to: "/spiking-up-down?sector=&industry=&category=all&spike=notes_offering&spiking=&start=&end=&sortby=&sortorder=&drawer=true",
      },
    ],
  },
  {
    label: "Insider Investors",
    icon: <AccountBalanceOutlinedIcon />,
    scale: 0.7,
    children: [
      {
        label: "Investors",
        children: [
          {
            label: "Top 100",
            subLabel: "Money Manager",
            to: "/funds/top100/fundamental",
          },
          {
            label: "Universe",
            subLabel: "Fund",
            to: "/funds/universe/fundamental",
          },
          {
            label: "Sovereign Wealth",
            subLabel: "Fund",
            to: "/funds/sovereign/fundamental",
          },
          {
            label: "Pension",
            subLabel: "Fund",
            to: "/funds/pension/fundamental",
          },
          {
            label: "Retirement",
            subLabel: "Fund",
            to: "/funds/retirement/fundamental",
          },
          {
            label: "C-Suite",
            subLabel: "Management",
            to: "/funds/csuite/insider",
          },
          {
            label: "Politician",
            subLabel: "Senate & Congress",
            to: "/funds/politicians",
          },
          { label: "CNBC", subLabel: "Guest", to: "/funds/cnbc" },
        ],
      },
      {
        label: "Active Traders",
        children: [
          {
            label: "Cathie Wood",
            subLabel: "Aggressive",
            to: "/investor/ark/fundamental",
          },
          {
            label: "Warren Buffett",
            subLabel: "Moderate",
            to: "/investor/warren/fundamental",
          },
          {
            label: "Temasek Holdings",
            subLabel: "Conservative",
            to: "/investor/temasek/fundamental",
          },
        ],
      },
      {
        label: "Influencers",
        children: [
          {
            label: "Billionaire",
            to: "/dailytrades/bought?tags=billionaire&start=&end=&bids=&cids=",
          },
          {
            label: "Millionaire",
            to: "/dailytrades/bought?tags=millionaire&start=&end=&bids=&cids=",
          },
          {
            label: "Whale",
            to: "/dailytrades/bought?tags=whale&start=&end=&bids=&cids=",
          },
        ],
      },
      { label: "Spiking Robot", to: "/feed?space_id=43" },
      {
        label: "Race to 100",
        to: "/race-to-100?showMe=&racesOf=&sortby=goal&sortorder=desc&bid=&cid=&filter=",
      },
      {
        label: "Million Dollar Trades",
        to: "/million-dollar-trades?category=more_than_1_million&sortby=&sortorder=",
      },
    ],
  },
  {
    label: "Market Timing",
    icon: <HourglassEmptyOutlinedIcon />,
    scale: 0.7,
    children: [
      {
        label: "Cryptos",
        subLabel: "COIN Method",
        to: "/cryptos/candle/bitcoin",
      },
      {
        label: "Options",
        // subLabel: "TIME Method",
        // to: "/options/company/territory/11207",
        subLabel: "Coming Soon",
        to: "#",
      },
      {
        label: "Stocks",
        subLabel: "FIAT Method",
        to: "/company/fundamentals/11207/calls?date=",
      },
    ],
  },
  {
    label: "Holy Grail Portfolios",
    icon: <DifferenceOutlinedIcon />,
    scale: 0.7,
    tooltip:
      "Discover Spiking’s Holy Grail: a portfolio built on insider knowledge, economic models, and timeless investment strategies.",
    children: [
      {
        label: "Cryptos",
        subLabel: "Portfolio",
        to: "/holygrail-cryptos?portfolio_id=1",
      },
      {
        label: "Options",
        subLabel: "Portfolio",
        to: "/holygrail-options?portfolio_id=1",
      },
      {
        label: "Stocks",
        subLabel: "Portfolio",
        to: "/holygrail?portfolio_id=1",
      },
      { label: "Land", subLabel: "Coming Soon", to: "#" },
      {
        label: "Wealth",
        subLabel: "Portfolio",
        to: "/holygrail-wealth?portfolio_id=1",
      },
    ],
  },
  {
    label: "Legacy",
    icon: <MilitaryTechOutlinedIcon />,
    scale: 0.7,
    tooltip:
      "Build and secure your investing legacy with long-term, wealth-building strategies tailored to your goals.",
    children: [
      { label: "Employee", subLabel: "Coming Soon", to: "#" },
      { label: "Self-Employed", subLabel: "Coming Soon", to: "#" },
      { label: "Business Owner", subLabel: "Coming Soon", to: "#" },
      { label: "Investor", subLabel: "Coming Soon", to: "#" },
      { label: "Berkshire Hathaway", subLabel: "Coming Soon", to: "#" },
    ],
  },
];
